import { HeaderNavListType } from '@/typedir/types'

export const headerNavList: HeaderNavListType[] = [
  { url: '/group/altilan', label: 'トップ', labelEnglish: 'TOP' },
  {
    url: '/group/altilan#company',
    label: '会社情報',
    labelEnglish: 'ABOUT US'
  },
  {
    url: 'https://relic.co.jp/contact/',
    label: 'お問い合わせ',
    isContact: true
  }
]
