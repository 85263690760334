import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2024.08.21',
    text: '株式会社ALTILANのコーポレートサイトをオープンいたしました。',
    url: 'https://altilan.co.jp/'
  },
  {
    date: '2024.08.08',
    text: '9/22(日)に、浜松青年会議所主催の「DISCOVER U あなたの可能性を見つける」（後援：浜松市）に代表の永利が登壇いたします。',
    url: 'https://www.hamamatsujc.or.jp/2024/du/'
  },
  {
    date: '2024.06.19',
    text: '中日新聞にて、6/12に主催したイベントを取り上げていただきました（共催：Co-startup Space & Community FUSE、特別協力：一般社団法人SPACETIDE、後援：浜松市）',
    url: 'https://biz.chunichi.co.jp/news/article/10/85010/12/'
  },
  {
    date: '2024.06.15',
    text: '静岡新聞にて、6/12に主催したイベントを取り上げていただきました（共催：Co-startup Space & Community FUSE、特別協力：一般社団法人SPACETIDE、後援：浜松市）',
    url: 'https://www.at-s.com/news/article/shizuoka/1494542.html'
  },
  {
    date: '2024.06.07',
    text: '静岡新聞にて株式会社ALTILANの設立を取り上げていただきました。',
    url: 'https://www.at-s.com/sp/news/article/shizuoka/1486573.html'
  },
  {
    date: '2024.05.31',
    text: 'UchuBizにて株式会社ALTILANの設立を取り上げていただきました。',
    url: 'https://uchubiz.com/article/new47562/'
  },
  {
    date: '2024.05.29',
    text: 'Relicホールディングス、宇宙ビジネスを専門とし、宇宙機器開発および事業推進、異業種の参入支援を行う「株式会社ALTILAN」を静岡県・浜松市に設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000278.000016318.html'
  }
  // {date: "2099.09.28", text: "テスト", url: "https://prtimes.jp/main/html/rd/p/000000085.000016318.html"}
]
