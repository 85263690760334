import { FooterBottomType } from '@/typedir/types'

export const footerBottomList: FooterBottomType[] = [
  {
    url: '/',
    label: '株式会社Relicホールディングストップ'
  },
  {
    url: '/group/altilan/policy',
    label: 'プライバシーポリシー'
  }
]
