import React from 'react'

import { AltilanPageLayout } from '@/components/altilan/altilanPageLayout'
import { tempNewsList } from '@/constants/news/altilan'
import { AltilanHead } from '@/components/altilan/altilanHead'
import { NewsList } from '@/components/common/news'

export default function AltilanNews(): JSX.Element {
  return (
    <>
      <AltilanHead
        title="人が自由に宇宙を行き来する時代をつくる「株式会社ALTILAN」"
        url="/group/altilan"
      />
      <AltilanPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/Altilan/NEWS"
        breadcrumbsPath="/group/altilan/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </AltilanPageLayout>
    </>
  )
}
